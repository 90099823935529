import { Cookies } from 'react-cookie';

import { SAVED_USER_ROLE, SEEN_WELCOME_PAGE_STORAGE_KEY } from '../components/teams/constants';

export function removeCookies() {
  const cookies = new Cookies();
  Object.keys(cookies.getAll() || {}).forEach((key) => {
    if (key !== SEEN_WELCOME_PAGE_STORAGE_KEY && key !== SAVED_USER_ROLE) {
      cookies.remove(key);
    }
  });
}
