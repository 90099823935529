import { FieldValues, Path, useController } from 'react-hook-form';
import Switch from 'components/ui/inputs/Switch';
export interface IFormToggle<TFormValues> {
  isDisabled?: boolean;
  cyElementType?: string;
  // React-hook-form related props
  name: Path<TFormValues>;
}

/**
 * @deprecated Use `components/ui/inputs/form/FormSwitchInput` instead.
 */
export default function FormToggle<TFormValues extends FieldValues>({
  name,
  isDisabled
}: IFormToggle<TFormValues>) {
  const {
    field: {
      onChange: onChangeInput,
      value
    }
  } = useController({
    name
  });
  return <Switch disabled={isDisabled} checked={value} onCheckedChange={nextChecked => onChangeInput(nextChecked)} />;
}