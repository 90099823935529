import { isDevelopmentEnv } from './env-helper';

const generateCSP = () => {
  const defaultSrc = isDevelopmentEnv()
    ? `'self' ${process.env.NEXT_PUBLIC_GRAPHQL_URL?.split('/')[2]}`
    : `'self'`;

  const scriptSrc = `'unsafe-inline' 'unsafe-eval' https: http:`;

  const eventGatewayLocalDomain = isDevelopmentEnv() ? '*.eppo.localhost' : '';

  return `
object-src 'none';
script-src ${scriptSrc};
base-uri 'self';
default-src ${defaultSrc} *.eppo.cloud *.commandbar.com *.rudderstack.com *.rudderlabs.com *.intercom.io *.browser-intake-datadoghq.com *.maze.co;
style-src 'self' 'unsafe-inline' *.commandbar.com;
img-src ${defaultSrc} *.amazonaws.com *.gravatar.com *.wp.com *.googleusercontent.com *.googleapis.com  *.intercom.io *.intercomcdn.com *.intercomassets.com cdn.auth0.com data:;
worker-src 'self' blob:;
font-src 'self' *.intercomcdn.com fonts.gstatic.com;
connect-src ${defaultSrc} *.eppo.cloud ${eventGatewayLocalDomain} *.commandbar.com *.rudderstack.com *.rudderlabs.com wss://*.intercom.io https://*.intercom.io *.browser-intake-datadoghq.com *.maze.co https://us-central1-eppo-qa.cloudfunctions.net https://browser-intake-datadoghq.com;
media-src 'self' *.intercomcdn.com;
frame-ancestors 'self';
`
    .replace(/\n/g, ' ')
    .trim();
};

export default generateCSP;
