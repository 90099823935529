import { useEffect, useMemo, useState } from 'react';
import { EFeatureFlag } from 'constants/featureFlags';
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from 'react-relay';
import { graphql } from 'relay-runtime';
import { useStringFlagOn } from 'components/feature-flag/hooks/useFlagAssignment';
import { SearchInput } from 'components/inputs/styled';
import { Suspense } from 'components/Suspense';
import Kbd from 'components/ui/typography/Kbd';
import { isMacFunc } from 'helpers/platform-helper';
import { feedDataToCommandBar, openCommandBar } from './helpers/command-bar-helper';
import { SearchBarDiv, SearchBarInputDiv, SearchBarInputPlusSpan, SearchBarOverlay } from './styled';
import { SearchBar_Query } from './__generated__/SearchBar_Query.graphql';
export const SEARCH_BAR_QUERY = graphql`
  query SearchBar_Query {
    metrics {
      id
      name
      entity {
        id
        name
      }
    }
    assignmentSources {
      id
      name
      dimensions {
        id
        dimensionName
      }
    }
    metricEvents {
      id
      name
      metricEventMeasures {
        id
        measureName
      }
    }
    entityDimensionSources {
      id
      name
      dimensions {
        id
        dimensionName
      }
    }
  }
`;
graphql`
  query SearchBar_Experiment_Query($input: SearchExperimentInput!) {
    searchExperiment(input: $input) {
      id
      name
    }
  }
`;
export const SEARCH_BAR_EXPERIMENT_QUERY_RAW = `
  query SearchBar_Experiment_Query($input: SearchExperimentInput!) {
    searchExperiment(input: $input) {
      id
      name
    }
  }
`;
export default function SearchBarContainer() {
  const [queryRef, loadQuery] = useQueryLoader<SearchBar_Query>(SEARCH_BAR_QUERY);
  const renderSearchBar = useStringFlagOn(EFeatureFlag.SearchBarV2);
  useEffect(() => {
    if (renderSearchBar) {
      loadQuery({});
    }
  }, [loadQuery, renderSearchBar]);
  return <Suspense fallback={<></>}>
      {renderSearchBar && queryRef && <SearchBar queryRef={queryRef} />}
    </Suspense>;
}
export interface ISearchBar {
  queryRef: PreloadedQuery<SearchBar_Query>;
}
function SearchBar({
  queryRef
}: ISearchBar) {
  const data = usePreloadedQuery<SearchBar_Query>(SEARCH_BAR_QUERY, queryRef);
  const [isHoverActive, setIsHoverActive] = useState<boolean>(false);
  const handleClick = () => {
    openCommandBar();
  };
  useEffect(() => {
    // update command bar when data change
    feedDataToCommandBar(data);
  }, [data]);
  const isMac: boolean = useMemo<boolean>(() => isMacFunc(), []);
  return <SearchBarDiv onClick={handleClick} onMouseEnter={() => setIsHoverActive(true)} onMouseLeave={() => setIsHoverActive(false)} data-cy-element-type="search-bar">
      <SearchBarInputDiv>
        <SearchInput placeholder="Search" height={32} width="100%" disabled />
      </SearchBarInputDiv>
      <Kbd dark={!isHoverActive}>{isMac ? '⌘' : 'Ctrl'}</Kbd>
      <SearchBarInputPlusSpan>+</SearchBarInputPlusSpan>
      <Kbd dark={!isHoverActive}>K</Kbd>
      <SearchBarOverlay />
    </SearchBarDiv>;
}