import styled from '@emotion/styled';
import { fontSize, lineHeight } from 'theme';
import { colors } from 'theme/colors';
export interface ILabel {
  margin?: string;
}
const Label = styled.label<ILabel>({
  fontSize: fontSize.bodySmall,
  fontFamily: 'Cofo Sans, sans-serif',
  lineHeight: lineHeight.heading,
  fontWeight: 500,
  color: colors.text.secondary
},
// Overrides
({
  margin = '0'
}) => ({
  margin
}));
export default Label;