import { ReactNode, useState } from 'react';
import { COLOR_GREY_100, COLOR_WHITE } from 'constants/colors';
import styled from '@emotion/styled';
import { Resizable, ResizeCallback } from 're-resizable';
import { spacing } from 'theme';
import ClickOutsideWrapper from 'components/click-outside-wrapper/ClickOutsideWrapper';
import { FlexBoxItem } from 'components/style-components/FlexBoxItem';
import Heading from 'components/ui/typography/Heading';
import { zIndexSticky } from 'styles/z-index';
import { SidebarContainer, SidebarDiv } from './styled';
export interface ISidebar {
  children: ReactNode;
  width?: number;
  animate: boolean;
  onClickOutside: () => void;
  clickOutsideIgnoreId?: string;
  title?: string;
  actions?: ReactNode;
}
const DEFAULT_WIDTH = 376;
export function Sidebar({
  children,
  width = DEFAULT_WIDTH,
  animate,
  onClickOutside,
  clickOutsideIgnoreId,
  title,
  actions
}: ISidebar) {
  const [calculatedWidth, setCalculatedWidth] = useState<number>(width);
  const onResizeStopHandle: ResizeCallback = (_event, _direction, _element, {
    width: widthOffset
  }) => {
    setCalculatedWidth(calculatedWidth + widthOffset);
  };
  return <ClickOutsideWrapper onClickOutside={onClickOutside} ignoreId={clickOutsideIgnoreId}>
      <SidebarContainer onClick={e => e.stopPropagation()}>
        <FlexBoxItem flexGrow={1} />
        <Resizable size={{
        width: calculatedWidth,
        height: '100vh'
      }} onResizeStop={onResizeStopHandle} enable={{
        top: false,
        right: false,
        bottom: false,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }} style={{
        pointerEvents: 'all'
      }} minWidth={width}>
          <SidebarDiv animate={animate}>
            {(title || actions) && <SidebarHeaderBox>
                {title && <Heading variant="h3">{title}</Heading>}
                <FlexBoxItem flexGrow={1} />
                {actions}
              </SidebarHeaderBox>}
            {children}
          </SidebarDiv>
        </Resizable>
      </SidebarContainer>
    </ClickOutsideWrapper>;
}
const SidebarHeaderBox = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: spacing.lg,
  borderBottom: `1px solid ${COLOR_GREY_100}`,
  position: 'sticky',
  top: 0,
  background: COLOR_WHITE,
  zIndex: zIndexSticky,
  margin: 1
});