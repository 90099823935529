import React from 'react';
import loaderBackgroud from './img/generic_loading.svg';
import { PageLoaderWrapper, PageLoaderDiv } from './styled';
export type IPageLoaderSize = 'small' | 'large';
export interface IPageLoader {
  height?: number | string;
}
export function PageLoader({
  height = '100%'
}: IPageLoader) {
  return <PageLoaderWrapper height={height}>
      <PageLoaderDiv backgroundImage={loaderBackgroud} />
    </PageLoaderWrapper>;
}