import { DividerLine as DividerLineDiv, DividerVerticalLine } from './styled';
interface IDividerLine {
  isVertical?: boolean;
}
export function DividerLine({
  isVertical
}: IDividerLine) {
  if (isVertical) {
    return <DividerVerticalLine />;
  }
  return <DividerLineDiv />;
}