import { COLOR_BLACK, COLOR_BORDER, COLOR_ERROR, COLOR_GREY_100, COLOR_GREY_200, COLOR_GREY_300, COLOR_GREY_400, COLOR_PRIMARY_100, COLOR_PRIMARY_200, COLOR_PRIMARY_300, COLOR_PRIMARY_400, COLOR_PRIMARY_600, COLOR_WHITE } from 'constants/colors';
import { SPACE_L, SPACE_M, SPACE_S } from 'constants/sizes';
import { FONT_SIZE_REGULAR, FONT_SIZE_SMALL } from 'constants/typography';
import styled from '@emotion/styled';
import { Property } from 'csstype';
import { spacing } from 'theme';
import { zIndexDropdown, zIndexPopover } from 'styles/z-index';
import { colors } from 'theme/colors';
import { palette } from 'theme/palette';
import { FontFamilyEnum } from '../typography/styled';
import Heading from '../ui/typography/Heading';
import calendarGreyedIcon from './img/calendar-grey.svg';
import calendarIcon from './img/calendar.svg';
import checkboxCheckedPartialy from './img/checkbox-partial.svg';
import clockIcon from './img/clock.svg';
import caretIcon from './img/down-arrow.svg';
import icon from './img/search.svg';
const DEFAULT_DROPDOWN_WIDTH = 175;

// Define a constant for the slider thumb dimensions
const SLIDER_THUMB_SIZE = 13; // px

export const TextAreaInline = styled.textarea(() => ({
  width: '100%',
  border: 'none',
  color: colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  borderRadius: 8,
  outline: 'none',
  padding: 8,
  '::placeholder': {
    color: colors.text.secondary
  },
  resize: 'none',
  '&:focus': {
    border: `1px solid ${COLOR_PRIMARY_600}`
  }
}));
export type SelectInputHeight = 32 | 48;
export const Select = styled.select<{
  width?: string;
  isDisabled?: boolean;
  margin?: string;
  height?: SelectInputHeight;
}>(({
  width,
  isDisabled = false,
  margin,
  height = 32
}) => {
  let backgroundPosition: string;
  switch (height) {
    case 32:
      backgroundPosition = 'right 8px top 10px';
      break;
    case 48:
      backgroundPosition = 'right 8px top 18px';
      break;
    default:
      throw new Error('Unsupported height');
  }
  return {
    height: height,
    color: colors.text.primary,
    fontSize: FONT_SIZE_REGULAR,
    lineHeight: `${height}px`,
    width: width,
    border: `1px solid ${COLOR_BORDER}`,
    borderRadius: 8,
    backgroundColor: isDisabled ? COLOR_GREY_300 : COLOR_WHITE,
    backgroundImage: `url(${caretIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 12px',
    backgroundPosition: backgroundPosition,
    paddingLeft: 8,
    paddingRight: 28,
    appearance: 'none',
    margin: margin
  };
});
export const SelectContainer = styled.div(() => ({
  position: 'relative'
}));
export const TextInput = styled.input<{
  disabled?: boolean;
  width?: string;
  maxWidth?: string;
  margin?: string;
  isValid?: boolean;
  bgColor?: string;
  borderColor?: string;
  textAlign?: 'center' | 'right' | 'left';
  readonly?: boolean;
}>(({
  bgColor,
  disabled = false,
  width,
  maxWidth,
  margin = '0',
  isValid = true,
  borderColor = COLOR_BORDER,
  textAlign = 'left',
  readonly
}) => ({
  textAlign,
  color: colors.text.primary,
  width,
  maxWidth,
  fontSize: FONT_SIZE_REGULAR,
  height: 32,
  paddingLeft: 8,
  paddingRight: 8,
  lineHeight: '32px',
  border: isValid ? `1px solid ${borderColor}` : `1px solid ${COLOR_ERROR}`,
  borderRadius: 8,
  margin: margin,
  backgroundColor: disabled || readonly ? COLOR_GREY_300 : bgColor,
  '&:focus, &:active': {
    outline: 'none',
    boxShadow: `0px 0px 0px 2px ${isValid ? borderColor : COLOR_ERROR}7F`
  }
}));
export const TextArea = styled.textarea<{
  disabled?: boolean;
  width: string;
  margin?: string;
  isValid?: boolean;
  bgColor?: string;
  borderColor?: string;
}>(({
  bgColor,
  disabled = false,
  width,
  margin = '0',
  isValid = true,
  borderColor = COLOR_BORDER
}) => ({
  color: colors.text.primary,
  width: width,
  fontSize: FONT_SIZE_REGULAR,
  padding: '4px 8px 4px 8px',
  lineHeight: '19.5px',
  border: borderColor ? isValid ? `1px solid ${borderColor}` : `1px solid ${COLOR_ERROR}` : 'none',
  borderRadius: 8,
  margin: margin,
  backgroundColor: disabled ? COLOR_GREY_300 : bgColor,
  '&:focus, &:active': {
    outline: 'none',
    boxShadow: `0px 0px 0px 2px ${isValid ? borderColor : COLOR_ERROR}7F`
  }
}));
export const CheckboxInputWrapper = styled.div(() => ({
  width: 16,
  height: 16,
  position: 'relative'
}));
export const CheckboxInput = styled.input(() => ({
  position: 'absolute',
  width: 1,
  height: 1,
  visibility: 'hidden'
}));
export const CheckboxVisual = styled.div<{
  isDisabled?: boolean;
  isChecked?: boolean;
  isCheckedPartialy?: boolean;
  color?: string;
}>(() => ({
  width: 16,
  height: 16,
  border: `1px solid ${COLOR_GREY_400}`,
  backgroundColor: COLOR_GREY_100,
  cursor: 'pointer'
}), ({
  isChecked = false,
  color = colors.text.link
}) => isChecked && {
  borderColor: color,
  backgroundColor: color,
  backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")`
}, ({
  isDisabled = false
}) => isDisabled && {
  backgroundColor: COLOR_GREY_400,
  borderColor: COLOR_GREY_400,
  cursor: 'not-allowed'
}, ({
  isCheckedPartialy = false
}) => isCheckedPartialy && {
  borderColor: colors.text.link,
  // TODO: Use other dedicated token.
  backgroundColor: colors.text.link,
  backgroundImage: `url(${checkboxCheckedPartialy})`,
  backgroundPosition: '-5px -5px'
});
export const RadioInputWrapper = styled.div(() => ({
  width: 16,
  height: 16,
  position: 'relative'
}));
export const RadioInput = styled.input(() => ({
  position: 'absolute',
  width: 1,
  height: 1,
  visibility: 'hidden'
}));
export const RadioButtonInputWrapper = styled.div<{
  width: number;
}>(({
  width
}) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 2,
  backgroundColor: COLOR_GREY_200,
  borderRadius: '8px',
  width
}));
export const RadioVisual = styled.div<{
  isDisabled?: boolean;
  isChecked?: boolean;
}>(() => ({
  width: 16,
  height: 16,
  border: `1px solid ${COLOR_GREY_400}`,
  backgroundColor: COLOR_GREY_100,
  cursor: 'pointer',
  borderRadius: '8px'
}), ({
  isChecked = false
}) => isChecked && {
  borderColor: colors.text.link,
  // TODO: Use other dedicated token.
  backgroundColor: colors.text.link,
  backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")`
}, ({
  isChecked = false,
  isDisabled = false
}) => isChecked && isDisabled && {
  backgroundColor: COLOR_GREY_400,
  borderColor: COLOR_GREY_400
});
export const RadioOption = styled.label(() => ({
  display: 'flex',
  marginTop: '8px',
  alignItems: 'center'
}));
export const RadioLabel = styled.span(() => ({
  fontSize: '13px',
  lineHeight: '16px',
  letterSpacing: '-0.2px',
  marginLeft: '12px'
}));
export const TextAreaInput = styled.textarea<{
  isDisabled?: boolean;
  width: string;
  height: string;
  margin: string;
  isValid: boolean;
  placeHolderLineHeight?: string;
  placeHolderColor?: string;
  fontFamily?: string;
}>(({
  isDisabled = false,
  width,
  height = '76px',
  margin,
  isValid,
  fontFamily = FontFamilyEnum.DEFAULT,
  placeHolderLineHeight = '32px',
  placeHolderColor = colors.text.secondary
}) => ({
  color: colors.text.primary,
  width: width,
  fontSize: 12,
  height: height,
  paddingLeft: 8,
  lineHeight: '19px',
  border: isValid ? `1px solid ${COLOR_BORDER}` : '1px solid red',
  borderRadius: 8,
  margin: margin,
  resize: 'none',
  backgroundColor: isDisabled ? COLOR_GREY_300 : COLOR_WHITE,
  fontFamily: fontFamily,
  '&::placeholder': {
    fontFamily: FontFamilyEnum.DEFAULT,
    lineHeight: placeHolderLineHeight,
    fontSize: FONT_SIZE_REGULAR,
    color: placeHolderColor
  },
  '&:focus, &:active': {
    outline: 'none'
  }
}));
export const FormTextInput = styled.input<{
  border?: string;
  outlineColor?: string;
  height?: number | string;
  disabled?: boolean;
  width?: number | string;
  padding?: Property.Padding;
  removeOutline?: boolean;
}>(({
  border = `1px solid ${COLOR_BORDER}`,
  outlineColor = COLOR_GREY_300,
  height = 32,
  disabled = false,
  width,
  padding = null,
  removeOutline = false // outlineColor doesn't remove blue outline in Firefox for some reason.
}) => ({
  width: width || '100%',
  padding: padding || '0 10px',
  height: height,
  border: border,
  borderRadius: 8,
  backgroundColor: disabled ? COLOR_GREY_300 : COLOR_WHITE,
  color: colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  '&:focus, &:active': {
    outlineColor,
    ...(removeOutline ? {
      outline: 'none'
    } : {})
  }
}));
export const DateTimeInputWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
}));
export const DateTextInput = styled.input<{
  border?: string;
  outlineColor?: string;
  height?: number | string;
  disabled?: boolean;
}>(({
  border = `1px solid ${COLOR_BORDER}`,
  outlineColor = COLOR_GREY_300,
  height = 32,
  disabled = false
}) => ({
  width: '100%',
  padding: '0 10px',
  height: height,
  border: border,
  borderRadius: 8,
  backgroundColor: disabled ? COLOR_GREY_300 : COLOR_WHITE,
  color: colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  backgroundImage: `url(${disabled ? calendarGreyedIcon : calendarIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '24px 24px',
  backgroundPosition: '4px 2px',
  paddingLeft: 36,
  marginRight: 10,
  '&:focus, &:active': {
    outlineColor: outlineColor
  }
}));
export const TimeTextInput = styled.input<{
  border?: string;
  outlineColor?: string;
  height?: number | string;
  disabled?: boolean;
}>(({
  border = `1px solid ${COLOR_BORDER}`,
  outlineColor = COLOR_GREY_300,
  height = 32,
  disabled = false
}) => ({
  width: 110,
  padding: '0 10px',
  height: height,
  border: border,
  borderRadius: 8,
  backgroundColor: disabled ? COLOR_GREY_300 : COLOR_WHITE,
  color: colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  backgroundImage: `url(${clockIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '24px 24px',
  backgroundPosition: '4px 2px',
  paddingLeft: 36,
  '&:focus, &:active': {
    outlineColor: outlineColor
  }
}));
export const FormTextArea = styled.textarea<{
  fillArea?: boolean;
  outlineColor?: string;
  isValid?: boolean;
  resize?: 'vertical' | 'none';
}>(({
  fillArea = false,
  outlineColor = COLOR_GREY_300,
  isValid = true,
  resize = 'vertical'
}) => ({
  height: fillArea ? '100%' : 120,
  width: fillArea ? '100%' : undefined,
  padding: 10,
  border: isValid ? `1px solid ${COLOR_BORDER}` : '1px solid red',
  borderRadius: 8,
  backgroundColor: COLOR_WHITE,
  color: colors.text.primary,
  resize,
  fontSize: FONT_SIZE_REGULAR,
  '&:focus, &:active': {
    outlineColor: outlineColor
  }
}));
export const WrappedEditableTextDiv = styled.div({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word'
});
export const TextAreaDiv = styled.div<{
  isEmpty?: boolean;
  padWhenEmpty?: boolean;
}>(({
  isEmpty = false,
  padWhenEmpty = false
}) => ({
  color: isEmpty ? colors.text.secondary : colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  fontStyle: isEmpty ? 'italic' : 'normal',
  paddingBottom: isEmpty && padWhenEmpty ? '16px' : 'inherit',
  whiteSpace: 'pre-wrap'
}));
export const SearchInput = styled.input<{
  width: number | string;
  bgColor?: string;
  border?: string;
}>(({
  width,
  bgColor = COLOR_GREY_100,
  border = 'none'
}) => ({
  height: 32,
  width: width,
  borderRadius: 4,
  border,
  backgroundColor: bgColor,
  backgroundImage: `url(${icon})`,
  backgroundSize: '18px 18px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '10px 8px',
  paddingLeft: 36,
  color: colors.text.secondary,
  fontSize: FONT_SIZE_REGULAR,
  letterSpacing: 0,
  lineHeight: '16px'
}));
export const FilterInputWrapper = styled.div(() => ({
  position: 'relative',
  width: '100%',
  height: 'fit-content'
}));
export const FilterInputSearchWrapper = styled.div(() => ({
  width: 340,
  border: `1px solid ${COLOR_BORDER}`,
  borderRadius: 8,
  backgroundColor: COLOR_WHITE,
  boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
  padding: '8px 0',
  position: 'absolute',
  top: '100%',
  left: 0,
  zIndex: zIndexDropdown
}));
export const FilterInputSearchWrapperListHead = styled.div(() => ({
  color: colors.text.secondary,
  fontSize: 11.5,
  lineHeight: '24px',
  textTransform: 'uppercase',
  padding: '0 8px'
}));
export const FilterInputSearchWrapperList = styled.div(() => ({
  padding: '8px 0'
}));
export const FilterInputSearchWrapperListItem = styled.div<{
  isSelected?: boolean;
}>(({
  isSelected = false
}) => ({
  color: colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '32px',
  display: 'flex',
  background: isSelected ? COLOR_PRIMARY_100 : COLOR_WHITE,
  padding: '0 8px',
  alignItems: 'center',
  cursor: 'pointer'
}));
export const FilterInputSearchWrapperListItemText = styled.div(() => ({
  flexGrow: 1
}));
export const FilterInputSearchWrapperHead = styled.div(() => ({
  padding: 8
}));
export const MultipleTextInputContainer = styled.div<{
  disabled?: boolean;
  invalid?: boolean;
  focused?: boolean;
}>(({
  disabled = false,
  invalid = false,
  focused = false
}) => {
  let borderColor = COLOR_BORDER;
  if (focused) {
    borderColor = COLOR_PRIMARY_400;
  }
  if (invalid) {
    borderColor = COLOR_ERROR;
  }
  return {
    minHeight: 32,
    border: `1px solid ${borderColor}`,
    borderRadius: 8,
    backgroundColor: disabled ? COLOR_GREY_300 : COLOR_WHITE,
    display: 'flex',
    flexFlow: 'wrap',
    gap: 4,
    width: '100%',
    paddingRight: 4,
    boxShadow: focused ? `0px 0px 0px 2px ${COLOR_PRIMARY_400}7F` : ''
  };
});
export const MultipleTextInputValue = styled.div<{
  readonly?: boolean;
}>(({
  readonly = false
}) => ({
  borderRadius: '6px',
  backgroundColor: readonly ? COLOR_GREY_200 : COLOR_PRIMARY_200,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: readonly ? COLOR_GREY_400 : COLOR_PRIMARY_300,
  fontSize: 11.5,
  lineHeight: '16px',
  color: colors.text.primary,
  padding: 4,
  margin: '4px 0 4px 4px',
  display: 'flex',
  alignItems: 'center'
}));
export const MultipleTextInputValueText = styled.div(() => ({
  marginRight: 8
}));
export const MultipleTextInputInput = styled.div(() => ({
  margin: '4px 0 4px 4px',
  flexGrow: 1
}));
export const FilterInputMultipleInputContainer = styled.div(() => ({
  marginBottom: 8
}));
export const FilterInputMultipleInputTitle = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  height: 32
}));
export const FilterInputMultipleInputTitleText = styled.div(() => ({
  color: colors.text.secondary,
  fontSize: 11.5,
  lineHeight: '14px',
  flexGrow: 1,
  textTransform: 'uppercase'
}));
export const SearchDropdownWrapper = styled.div<{
  paddingLeft?: number;
  showCaret?: boolean;
  fill?: 0 | 1;
  color?: string;
  isSmall?: boolean;
  isError?: boolean;
  disabled?: boolean;
  width?: number;
  isActive?: boolean;
}>(({
  paddingLeft = 0,
  showCaret = true,
  fill = 0,
  color = 'white',
  isSmall = false,
  isError = false,
  disabled = false,
  width = 334,
  isActive = false
}) => {
  if (isSmall) {
    return {
      paddingLeft: paddingLeft,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 110,
      height: 24,
      background: disabled ? COLOR_GREY_300 : COLOR_PRIMARY_100,
      border: isError ? `1px solid ${COLOR_ERROR}` : `1px solid ${COLOR_PRIMARY_300}`,
      boxSizing: 'border-box',
      borderRadius: 8,
      textTransform: 'uppercase',
      marginLeft: 6,
      padding: '4px 8px'
    };
  }
  let state: 'disabled' | 'error' | 'error-active' | 'active' | 'default' = 'default';
  if (disabled) {
    state = 'disabled';
  } else if (isError && isActive) {
    state = 'error-active';
  } else if (isError) {
    state = 'error';
  } else if (isActive) {
    state = 'active';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultStyle: Record<string, any> = {
    boxSizing: 'border-box',
    minHeight: 32,
    width: fill ? '100%' : width,
    border: `1px solid ${COLOR_BORDER}`,
    borderRadius: 8,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: paddingLeft,
    color: colors.text.secondary,
    backgroundImage: showCaret && `url(${caretIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 12px',
    backgroundPosition: 'right 8px top 10px',
    backgroundColor: color == 'grey' ? COLOR_GREY_100 : COLOR_WHITE,
    fontSize: FONT_SIZE_REGULAR,
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${COLOR_PRIMARY_400}`
    }
  };
  switch (state) {
    case 'active':
      return {
        ...defaultStyle,
        border: `1px solid ${COLOR_PRIMARY_400}`,
        backgroundColor: COLOR_PRIMARY_100
      };
    case 'error-active':
      return {
        ...defaultStyle,
        border: `1px solid ${COLOR_ERROR}`,
        backgroundColor: COLOR_PRIMARY_100,
        '&:hover': {}
      };
    case 'error':
      return {
        ...defaultStyle,
        border: `1px solid ${COLOR_ERROR}`,
        '&:hover': {}
      };
    case 'disabled':
      return {
        ...defaultStyle,
        backgroundColor: COLOR_GREY_100,
        cursor: 'not-allowed',
        '&:hover': {}
      };
    case 'default':
    default:
      return defaultStyle;
  }
});
export const SearchDropdownWrapperClickArea = styled.div(() => ({
  width: '100%',
  minHeight: 20,
  height: '100%',
  display: 'block',
  color: colors.text.primary
}));
export const SearchDropdownSelectedOptions = styled.div(() => ({
  display: 'flex',
  flexFlow: 'wrap',
  padding: '0 4px 4px 0',
  minHeight: 32,
  width: '100%',
  cursor: 'pointer'
}));
export const SearchDropdownSelectedOption = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8,
  backgroundColor: colors.hover.tertiary,
  padding: '4px 8px',
  margin: '4px 0 0 4px'
}));
export const SearchDropdownSelectedOptionText = styled.div(() => ({
  color: colors.text.primary,
  fontSize: 11.5,
  lineHeight: '16px',
  marginRight: 8
}));
export const SearchDropdownDropdown = styled.div<{
  maxWidth?: number;
  absolutePosition?: boolean; // useful to set to false when the dropdown is within a Popover
}>({
  zIndex: zIndexPopover,
  top: '100%',
  left: -1,
  maxHeight: 316,
  minWidth: 220,
  width: '100%',
  border: `1px solid ${COLOR_BORDER}`,
  borderRadius: 8,
  backgroundColor: COLOR_WHITE,
  boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
  paddingBottom: 6
}, ({
  maxWidth = 334
}) => ({
  maxWidth: maxWidth
}), ({
  absolutePosition = true
}) => ({
  position: absolutePosition ? 'absolute' : 'static'
}));
export const SearchDropdownDropdownSearch = styled.div(() => ({
  margin: 6,
  height: 32
}));
export const SearchDropdownOptionsLabel = styled.span(() => ({
  marginTop: 4,
  marginLeft: 18,
  fontWeight: 500,
  color: colors.text.secondary,
  fontSize: FONT_SIZE_SMALL,
  textTransform: 'uppercase'
}));
export const SearchDropdownDropdownLabel = styled.div(() => ({
  padding: `${SPACE_M}px ${SPACE_M}px ${SPACE_S}px ${SPACE_L}px`,
  fontSize: FONT_SIZE_SMALL,
  fontWeight: 500,
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: colors.text.secondary
}));
export const SearchDropdownDropdownOptions = styled.div(() => ({
  marginTop: 4,
  marginBottom: 4,
  maxHeight: 196,
  overflowY: 'auto'
}));
export const SearchDropdownFooter = styled.div(() => ({
  borderTop: `1px solid ${COLOR_GREY_300}`,
  padding: '8px 16px'
}));
export const SearchDropdownDropdownOption = styled.div<{
  isSelected?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
}>({
  display: 'flex',
  alignItems: 'center',
  minHeight: 40,
  padding: `0 ${spacing.lg}`,
  gap: spacing.sm,
  cursor: 'pointer',
  '& > div.plus-icon': {
    display: 'none'
  },
  '&:hover': {
    backgroundColor: COLOR_PRIMARY_100,
    '& > div.plus-icon': {
      display: 'inline-block'
    }
  }
}, ({
  isFocused = false
}) => isFocused ? {
  backgroundColor: COLOR_PRIMARY_100,
  '& > div.plus-icon': {
    display: 'inline-block'
  }
} : {}, ({
  isSelected = false
}) => isSelected ? {
  backgroundColor: COLOR_PRIMARY_100,
  border: `2px solid ${COLOR_PRIMARY_300}`,
  '&:hover > div.plus-icon': {
    display: 'none'
  }
} : {}, ({
  isDisabled = false
}) => isDisabled ? {
  backgroundColor: COLOR_PRIMARY_100,
  opacity: 0.5,
  cursor: 'not-allowed'
} : {});
export const SearchDropdownDropdownOptionText = styled.div(() => ({
  flexGrow: 1,
  color: colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  margin: '8px 8px 8px 0px'
}));
export const SearchDropdownDropdownEmptyOption = styled.div<{
  isFocused?: boolean;
}>({
  fontStyle: 'italic',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 16,
  marginBottm: 4,
  cursor: 'pointer',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: COLOR_PRIMARY_100
  }
}, ({
  isFocused = false
}) => isFocused ? {
  backgroundColor: COLOR_PRIMARY_100
} : {});
export const SearchDropdownDropdownBottomLinkButtonOption = styled.div(() => ({
  display: 'flex',
  alignItems: 'start',
  height: 32,
  cursor: 'pointer',
  backgroundColor: 'transparent'
}));
export const SearchDropdownDropdownEmptyOptionText = styled.div(() => ({
  flexGrow: 1,
  color: colors.text.primary,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  textTransform: 'none',
  margin: '8px 8px 8px 0px'
}));
export const DateTimeInputDelimiterWrapper = styled.div<{
  paddingBottom?: number;
}>(({
  paddingBottom = 20
}) => ({
  width: 40,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: paddingBottom,
  color: colors.text.secondary
}));
export const inlineTextInputCaptionStyle = (isError = false, isCaption = false) => ({
  fontWeight: 500,
  fontSize: 24,
  fontStyle: isCaption ? 'italic' : 'normal',
  color: isError ? COLOR_ERROR : isCaption ? colors.text.secondary : colors.text.primary,
  cursor: 'pointer',
  display: 'flex',
  gap: SPACE_M,
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%'
});
export const InlineTextInputCaption = styled.span<{
  isError?: boolean;
}>(({
  isError
}) => inlineTextInputCaptionStyle(isError, true));
export const InlineTextInputValue = styled(Heading)<{
  isError?: boolean;
  readonly?: boolean;
}>(({
  isError = false,
  readonly = false
}) => ({
  cursor: readonly ? 'text' : 'pointer',
  color: isError ? COLOR_ERROR : undefined,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: SPACE_M,
  whiteSpace: 'nowrap'
}));
export const InlineTextInputInputText = styled.input<{
  isError?: boolean;
}>(({
  isError = false
}) => ({
  border: 0,
  fontSize: 24,
  // todo make this magically match H1 styles
  outlineColor: isError ? COLOR_ERROR : undefined,
  width: '100%'
}));
export const SearchDropdownPlaceholder = styled.div<{
  styleType?: 'italic' | 'normal' | 'italic-start' | 'normal-dark';
}>(({
  styleType = 'italic'
}) => {
  switch (styleType) {
    case 'normal':
      return {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: FONT_SIZE_REGULAR,
        lineHeight: '20px',
        color: colors.text.secondary,
        textTransform: 'none'
      };
    case 'normal-dark':
      return {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: FONT_SIZE_REGULAR,
        lineHeight: '20px',
        color: colors.text.secondary,
        textTransform: 'none'
      };
    case 'italic':
    case 'italic-start':
    default:
      return {
        fontStyle: 'italic',
        fontWeight: 'normal',
        fontSize: 12,
        lineHeight: '20px',
        textAlign: styleType == 'italic-start' ? 'start' : 'center',
        letterSpacing: -0.2,
        color: colors.text.secondary,
        textTransform: 'none'
      };
  }
});
export const SearchDropdownSingleValue = styled.div(() => ({
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: spacing.sm
}));
export const DateTextInputDiv = styled.div(() => ({
  position: 'relative'
}));
export const DateCloseIconDiv = styled.div(() => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)'
}));
export const TimezoneText = styled.div(() => ({
  color: colors.text.secondary,
  padding: '4px',
  cursor: 'default',
  fontSize: FONT_SIZE_REGULAR
}));
export const CheckboxSelectInputSelectWrapper = styled.div<{
  active?: boolean;
  width?: number | string;
  readonly?: boolean;
  showCaret?: boolean;
  hideDefaultBorder?: boolean;
  hasError?: boolean;
  height?: string;
  backgroundColor?: Property.BackgroundColor;
  disableFocusStates?: boolean;
}>(({
  active = false,
  width = null,
  readonly,
  showCaret = true,
  hideDefaultBorder,
  hasError = false,
  height = '32px',
  backgroundColor,
  disableFocusStates = false
}) => {
  let state: 'readonly' | 'active' | 'default' = 'default';
  if (readonly) {
    state = 'readonly';
  } else if (active) {
    state = 'active';
  }
  const borderColor = hideDefaultBorder ? 'transparent' : hasError ? COLOR_ERROR : COLOR_BORDER;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const commonStyle: Record<string, any> = {
    width: width ?? 136,
    height,
    borderRadius: 8,
    border: `1px solid ${borderColor}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: !showCaret && 'center',
    padding: 4,
    paddingLeft: 8,
    paddingRight: showCaret ? 24 : 8,
    backgroundColor: readonly ? 'inherit' : backgroundColor ?? COLOR_WHITE,
    backgroundImage: showCaret && `url(${caretIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 12px',
    backgroundPosition: 'right 8px top 10px',
    cursor: readonly ? 'default' : 'pointer',
    '&:hover': !disableFocusStates && {
      border: `1px solid ${colors.border.secondary}`
    }
  };
  switch (state) {
    case 'readonly':
      return {
        ...commonStyle,
        '&:hover': {}
      };
    case 'active':
      return {
        ...commonStyle,
        border: disableFocusStates ? 'none' : `1px solid ${colors.border.secondary}`,
        backgroundColor: disableFocusStates ? 'transparent' : colors.background.purple
      };
    case 'default':
    default:
      return commonStyle;
  }
});
export const CheckboxSelectInputSelectWrapperText = styled.div<{
  showCaret?: boolean;
}>(({
  showCaret
}) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: showCaret ? 4 : 0
}));
export const CheckboxSelectInputPopover = styled.div<{
  width?: Property.Width;
}>(({
  width = 248
}) => ({
  width,
  display: 'flex',
  flexDirection: 'column',
  fontSize: FONT_SIZE_REGULAR,
  paddingBottom: spacing.md
}));
export const CheckboxSelectInputPopoverHeader = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  borderBottom: `1px solid ${COLOR_GREY_200}`,
  padding: '8px 16px'
}));
export const CheckboxSelectInputPopoverFooter = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `1px solid ${COLOR_GREY_200}`,
  padding: `${spacing.xs} ${spacing.lg}`
}));
export const CheckboxSelectInputPopoverBody = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 16px',
  maxHeight: 160,
  overflowY: 'auto'
}));
export const CheckboxSelectInputPopoverBodyElement = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  margin: '4px 0'
}));
export const SliderInputDiv = styled.div(() => ({
  display: 'flex',
  alignItems: 'center'
}));
export const SliderTrack = styled.div<{
  background: string;
  maxAllowedValue?: number;
  min?: number;
  max?: number;
}>(({
  background,
  maxAllowedValue,
  min = 0,
  max = 100
}) => ({
  height: '2px',
  width: '100%',
  background,
  position: 'relative',
  // Conditionally render the max allowed value line using ::after
  ...(maxAllowedValue && {
    '::after': {
      content: '""',
      position: 'absolute',
      left: `calc(${(Math.max(0, maxAllowedValue) - min) / (max - min) * 100}% + ${(SLIDER_THUMB_SIZE + 1) / 2}px)`,
      height: '28px',
      width: '1px',
      backgroundColor: palette.neutral['600'],
      top: '50%',
      transform: 'translateY(-50%)'
    }
  })
}));
export const SliderInputField = styled.div<{
  error?: boolean;
  disabled?: boolean;
}>(({
  error = false,
  disabled = false
}) => ({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 32,
  background: disabled ? COLOR_GREY_200 : COLOR_WHITE,
  padding: 8,
  border: `1px solid ${error ? COLOR_ERROR : colors.border.primary}`,
  borderRadius: 8,
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  "input[type='number']": {
    MozAppearance: 'textfield'
  },
  '&:focus-within': {
    borderColor: colors.border.secondary
  }
}));
export const SliderRangeInput = styled.input(() => ({
  width: '100%',
  height: 16,
  minWidth: '25px',
  fontWeight: 400,
  fontSize: FONT_SIZE_REGULAR,
  lineHeight: '16px',
  color: colors.text.primary,
  textAlign: 'right',
  border: 'none',
  '&:focus': {
    outline: 'none'
  }
}));
export const SliderThumb = styled.div(() => ({
  height: `${SLIDER_THUMB_SIZE}px`,
  width: `${SLIDER_THUMB_SIZE}px`,
  borderRadius: `${SLIDER_THUMB_SIZE}px`,
  border: `1px solid ${colors.surface.secondary}`,
  backgroundColor: colors.surface.secondary,
  cursor: 'pointer'
}));
export const TimeInputWrapper = styled.div(() => ({
  marginLeft: 8
}));
export const InputError = styled.div(() => ({
  color: 'red',
  fontSize: 10,
  textTransform: 'none'
}));
export const FormNumberInputPrefix = styled.div(() => ({
  display: 'inline-block',
  position: 'absolute',
  left: 5,
  top: 3
}));
export const DropdownInputReadonly = styled.div<{
  width?: Property.Width;
}>(({
  width
}) => ({
  width: width,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  padding: `5px 20px 5px 9px`,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));
export const DropdownInputWrapper = styled.div<{
  includeSidePadding?: boolean;
  isDisabled?: boolean;
  blockWidth?: number | string;
  blockHeight?: number | string;
  isRoundedBorder?: boolean;
  bgColor?: string;
  borderColor?: string;
  hasSelected?: boolean;
}>(({
  includeSidePadding = true,
  isDisabled = false,
  blockWidth = DEFAULT_DROPDOWN_WIDTH,
  blockHeight = 32,
  isRoundedBorder = false,
  bgColor = COLOR_WHITE,
  borderColor = COLOR_BORDER,
  hasSelected = true
}) => ({
  fontSize: FONT_SIZE_REGULAR,
  color: !hasSelected ? colors.text.secondary : colors.text.primary,
  width: blockWidth,
  height: blockHeight,
  borderRadius: isRoundedBorder ? 8 : 4,
  border: `1px solid ${borderColor}`,
  display: 'flex',
  alignItems: 'center',
  padding: includeSidePadding ? '4px 36px 4px 8px' : '4px 0px',
  backgroundColor: isDisabled ? COLOR_GREY_300 : bgColor,
  backgroundImage: `url(${caretIcon})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '12px 12px',
  backgroundPosition: 'right 14px center',
  cursor: isDisabled ? 'default' : 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&:hover': {
    border: `1px solid ${colors.border.secondary}`
  }
}));
export const DropdownInputInvisibleWrapper = styled.div<{
  width?: Property.Width;
}>(({
  width
}) => ({
  width: width
}));
export const DropdownInputOptionsWrapper = styled.div<{
  blockWidth?: number | string;
  maxHeight: Property.MaxHeight | number;
}>(({
  blockWidth = DEFAULT_DROPDOWN_WIDTH,
  maxHeight
}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: blockWidth,
  fontSize: FONT_SIZE_REGULAR,
  maxHeight: maxHeight,
  padding: '8px 0 8px 0',
  overflowY: 'auto'
}));
export const DropdownInputOption = styled.div<{
  isDisabled?: boolean;
}>(({
  isDisabled = false
}) => ({
  fontSize: FONT_SIZE_REGULAR,
  color: isDisabled ? COLOR_GREY_400 : colors.text.primary,
  padding: '8px 16px',
  cursor: !isDisabled ? 'pointer' : 'not-allowed',
  '&:hover': !isDisabled && {
    backgroundColor: colors.hover.tertiary
  }
}));
export const DropdownInputSection = styled.div({
  fontSize: FONT_SIZE_REGULAR,
  color: colors.text.secondary,
  fontWeight: 500,
  padding: '8px 16px',
  textTransform: 'capitalize'
});
export const DropdownInputExtraText = styled.div<{
  blockWidth?: number | string;
}>(({
  blockWidth = DEFAULT_DROPDOWN_WIDTH
}) => ({
  padding: '8px 16px',
  width: blockWidth
}));
export const DropdownInputImgWrapper = styled.div<{
  isDisabled?: boolean;
  readonly?: boolean;
}>(({
  isDisabled,
  readonly
}) => ({
  cursor: readonly ? 'default' : 'pointer',
  backgroundColor: isDisabled ? COLOR_GREY_300 : COLOR_WHITE
}));
export const DropdownInputImgOption = styled.div<{
  isDisabled?: boolean;
  borderBottom?: boolean;
}>(({
  isDisabled = false,
  borderBottom = false
}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: FONT_SIZE_REGULAR,
  color: isDisabled ? COLOR_GREY_400 : colors.text.primary,
  padding: '8px 16px',
  cursor: !isDisabled ? 'pointer' : 'not-allowed',
  borderBottom: borderBottom ? `1px solid ${COLOR_BORDER}` : undefined,
  '&:hover': !isDisabled && {
    backgroundColor: colors.hover.tertiary
  }
}));
export const TimeInputDiv = styled.div(() => ({
  display: 'flex',
  alignItems: 'center'
}));
export const AutoCompleteWrapper = styled.div(() => ({
  display: 'flex',
  alignItems: 'center'
}));
export const SearchDropdownButton = styled.button<{
  selected?: boolean;
  disabled?: boolean;
}>(({
  selected = false,
  disabled = false
}) => ({
  display: 'flex',
  border: 'none',
  background: 'none',
  flexDirection: 'row',
  alignItems: 'center',
  fontWeight: 500,
  fontSize: FONT_SIZE_REGULAR,
  color: selected ? COLOR_BLACK : colors.text.link,
  lineHeight: '15.6px',
  cursor: disabled ? 'default' : 'pointer'
}));
export const VariantSelectWrapper = styled.div(() => ({
  fontWeight: 400,
  position: 'relative',
  color: colors.text.primary
}));
export const PrimaryMetricBadge = styled.div(() => ({
  color: colors.text.primary,
  fontSize: FONT_SIZE_SMALL,
  borderRadius: 6,
  backgroundColor: COLOR_GREY_200,
  width: 'fit-content',
  padding: '3px 4px',
  textTransform: 'uppercase'
}));
export const StepSelectBodyWrapper = styled.div(() => ({
  minWidth: 296,
  maxHeight: 240,
  display: 'flex',
  flexDirection: 'row'
}));
export const StepSelectColumn = styled.div(() => ({
  padding: `${SPACE_M}px 0`,
  width: 296,
  maxHeight: 240,
  borderLeft: `1px solid ${COLOR_GREY_200}`
}));
export const StepSelectColumnOptions = styled.div<{
  height?: string;
}>(({
  height = '100%'
}) => ({
  height,
  overflowY: 'auto'
}));
export const StepSelectOption = styled.div<{
  isSelected?: boolean;
  typeOfHint?: 'code' | 'text';
}>(({
  isSelected = false,
  typeOfHint = null
}) => ({
  display: 'flex',
  flexDirection: 'column',
  height: typeOfHint === 'code' ? 52 : typeOfHint === 'text' ? 50 : 33,
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  backgroundColor: isSelected ? colors.hover.tertiary : COLOR_WHITE
}));
export const StepSelectOptionHint = styled.span(() => ({
  color: colors.text.secondary,
  fontSize: FONT_SIZE_SMALL
}));