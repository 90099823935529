import { getCookie } from 'cookies-next';
import { NextRequest, NextResponse } from 'next/server';
import generateCSP from 'helpers/generate-csp';
import { SAVED_USER_ROLE, SEEN_WELCOME_PAGE_STORAGE_KEY } from './components/teams/constants';
export const AUTHENTICATION_PATHNAME = '/';

// List of redirects for the updated ff routes
const REDIRECTS_MAP: Record<string, string> = {
  '/feature-flags/holdouts': '/configuration/holdouts',
  '/feature-flags/audiences': '/configuration/audiences',
  '/feature-flags/environments': '/configuration/environments',
  '/feature-flags': '/configuration/feature-flags' // it's important to keep the feature-flags root route at the bottom
};
const LEGACY_FF_URLS = Object.keys(REDIRECTS_MAP);
const authPageRedirect = (req: NextRequest) => {
  return NextResponse.redirect(new URL(`${AUTHENTICATION_PATHNAME}?next=${encodeURIComponent(req.nextUrl.pathname)}`, req.nextUrl.origin));
};
function responseWithCSP(req: NextRequest) {
  const csp = generateCSP();
  const requestHeaders = new Headers(req.headers);
  requestHeaders.set('Content-Security-Policy', csp);
  const res = NextResponse.next({
    request: {
      headers: requestHeaders
    }
  });
  res.headers.set('Content-Security-Policy', csp);
  return res;
}
export async function middleware(req: NextRequest) {
  const pathname = req.nextUrl.pathname;
  const requestedPageIsAuth = pathname === AUTHENTICATION_PATHNAME;
  const jwt = getCookie('appSession', {
    req
  });
  const seenWelcomePage = req.cookies.get(SEEN_WELCOME_PAGE_STORAGE_KEY);
  const savedRole = req.cookies.get(SAVED_USER_ROLE)?.value;
  if (!jwt && !requestedPageIsAuth) {
    return authPageRedirect(req);
  }
  if (jwt && requestedPageIsAuth) {
    const redirect = !seenWelcomePage ? '/welcome' : savedRole && savedRole === 'Engineering' ? '/configuration/feature-flags' : '/experiments';
    return NextResponse.redirect(new URL(redirect, req.nextUrl.origin));
  }
  const legacyFFRoute = LEGACY_FF_URLS.find(url => pathname.startsWith(url));
  const {
    origin,
    search
  } = req.nextUrl;
  if (legacyFFRoute) {
    return NextResponse.redirect(new URL(`${pathname.replace(legacyFFRoute, REDIRECTS_MAP[legacyFFRoute])}${search}`, origin));
  }
  return responseWithCSP(req);
}
export const config = {
  matcher: [{
    source: '/((?!api|_next/static|_next/image|favicon.*).*)'
  }]
};