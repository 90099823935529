import React from 'react';
import styled from '@emotion/styled';
import { RoleSelectorWrapper } from 'components/login/styled';
import { FieldHelperText } from 'components/ui/inputs/Field';
export type DevOnlyLoginMethod = 'auth0' | 'admin' | 'data_owner' | 'experiment_editor' | 'viewer';
interface IDevOnlyRoleSelectorProps {
  loginMethod: DevOnlyLoginMethod;
  onChange: (value: DevOnlyLoginMethod) => void;
}
const SelectWrapper = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column'
}));
export const DevOnlyRoleSelector: React.FunctionComponent<IDevOnlyRoleSelectorProps> = ({
  loginMethod,
  onChange
}) => {
  const isDev = process.env.NEXT_PUBLIC_APP_ENV === 'dev' || process.env.NODE_ENV === 'development';
  return isDev ? <RoleSelectorWrapper>
      <SelectWrapper>
        <select onChange={e => {
        const value = e.target.value;
        onChange((value as DevOnlyLoginMethod));
      }} value={loginMethod}>
          <option value="auth0">Auth0</option>
          <option value="admin">Admin</option>
          <option value="data_owner">Data Owner</option>
          <option value="experiment_editor">Experiment Editor</option>
          <option value="viewer">Viewer</option>
        </select>
        <FieldHelperText>This dropdown is only visible in development</FieldHelperText>
      </SelectWrapper>
    </RoleSelectorWrapper> : <></>;
};